import {
    Model,
    STATUS_SUCCESS,
    STATUS_ERROR,
    IResponseGetList
} from "src/makes/Model";


interface IDemandCategory {
    id:string;
    parentId?:string;
    icon?:string;
    title:string;
}


class DemandCategory extends Model<IDemandCategory> {
    static modelName:string = "demand-category";
    static queryCount:string = "";
    static queryPage:string = "";
    static filters = {
        parentId: (category:IDemandCategory, parentId:string) => {
            return category.parentId === parentId;
        }
    };

    async getList(query:any = {}, count:number = 0, page:number = 0):IResponseGetList<IDemandCategory> {
        const {
            bounds,
            ...otherQuery
        } = query;

        // if(bounds) {
        //     const visibility = await this.dispatch("api/get", `/api/${this.accessType}/${this.modelName}/visible`, {
        //         headers: this.authHeaders,
        //         data: {
        //             bounds
        //         }
        //     });
        //
        //     console.log(visibility);
        // }

        const res = await this._getList({
            ...otherQuery,
            ...count > 0 || page > 0 ? {
                count,
                page: page + 1
            } : {}
        });

        const {
            response: {
                message = ""
            } = {}
        } = res;

        if(typeof res.count === "number") {
            const {
                count: total,
                data: items
            } = res;

            return {
                status: STATUS_SUCCESS,
                total,
                items
            };
        }

        return {
            status: STATUS_ERROR,
            message
        };
    }
}


export type {IDemandCategory};

export {DemandCategory};