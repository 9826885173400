import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";
import {SnackbarProvider} from "notistack";
import {
    ThemeProvider,
    CssBaseline
} from "@material-ui/core";

import App from "./views/App";

import theme from "./theme";
import store from "./store";

import * as serviceWorker from "./serviceWorker";


ReactDOM.render(
    <Provider store={store.getStore()}>
        <ThemeProvider theme={theme}>
            <CssBaseline />

            <SnackbarProvider maxSnack={3}>
                <Router>
                    <App />
                </Router>
            </SnackbarProvider>
        </ThemeProvider>
    </Provider>,
    document.getElementById("root")
);

serviceWorker.unregister();