import React, {useState, useEffect, useCallback} from "react";
import {
    useLocation,
    useParams,
    useHistory,
    matchPath,
    generatePath
} from "react-router-dom";
import {
    Grid,
    Typography
} from "@material-ui/core";

import {QueryString} from "src/makes/QueryString";
import {parseQuadKeys} from "src/tools";

import {
    Page,
    PageHeader,
    PageNavbar,
    PageWrapper,
    PageContent,
    PageColumn,
    PageSidebar,
    PageSidebarContent,
    PageBackButton,
    Pagination,
    MapEventClick
} from "src/views/blocks";

import {
    SuggestMap,
    SuggestDataQuality,
    SuggestRating,
    SuggestTop5
} from "./blocks";

import {SuggestOverview} from "./sidebars";

import {ROUTES} from "src/router";

import store, {
    useORM,
    CityCenter,
    ISuggest,
    Suggest,
    SuggestAnalytic,
    SuggestCategory,
    Tile,
    TileParameter
} from "src/store";

import "./index.scss";


type UrlParams = {
    suggestId:string;
};

const SuggestViewPage:React.FC = () => {
    const {
        search,
        pathname
    } = useLocation();
    const {suggestId} = useParams<UrlParams>();
    const history = useHistory();

    const [quadKeys, setQuadKeys] = useState(parseQuadKeys(search));

    const suggest:ISuggest|null = useORM((orm) => {
        return orm.byId(Suggest.modelName, suggestId);
    });
    const suggests:ISuggest[] = useORM((orm) => {
        return orm.filter(Suggest.modelName, {
            quadKey: quadKeys
        });
    });

    const page = suggests.findIndex((s:ISuggest) => {
        if(suggest) {
            return s.id === suggest.id;
        }

        return false;
    });

    const {
        address = "",
        slug = ""
    } = suggest || {};

    useEffect(() => {
        const loadData = async () => {
            await store.orm.search(CityCenter.modelName, {
                type: "suggestions"
            });

            await store.orm.search(SuggestCategory.modelName);
            await store.orm.search(TileParameter.modelName);
        };

        loadData();
    }, []);

    useEffect(() => {
        const quadKeys = parseQuadKeys(search);

        setQuadKeys(quadKeys);
    }, [search]);

    useEffect(() => {
        const loadData = async () => {
            await store.orm.search(SuggestAnalytic.modelName, {
                withSummary: true,
                suggestId
            });

            const {
                top5Ids = []
            } = suggest || {};

            for(let i in top5Ids) {
                const id = top5Ids[i];

                if(!store.orm.getters.byId(Suggest.modelName, id)) {
                    await store.orm.fetch(Suggest.modelName, id);
                }
            }
        };

        if(suggestId) {
            loadData();
        }
    }, [suggestId]);

    useEffect(() => {
        const loadData = async () => {
            await store.orm.search(Tile.modelName, {
                withSummary: true,
                quadKeys
            });
        };

        if(quadKeys.length > 0) {
            loadData();
        }
    }, [quadKeys]);

    const handleClickBack = useCallback(() => {
        const search = QueryString.stringify({
            quadKeys: quadKeys
        });

        history.push(
            generatePath(ROUTES.suggest, {
                suggestId
            }) + (search ? `?${search}` : "")
        );
    }, [suggestId, search]);

    const handleChangePage = useCallback((page:number) => {
        const suggests:ISuggest[] = store.orm.getters.filter("suggest", {
            quadKey: quadKeys
        });

        const suggest:ISuggest|null = suggests[page] ? suggests[page] : null;

        if(suggest) {
            let [
                ignore,
                pagePath
            ] = new RegExp("/suggestion/view/[^/]+(.*)").exec(pathname) || [];

            history.push("/suggestion/view/" + suggest.id + pagePath + search);
        }
    }, [pathname, search, quadKeys]);

    const handleClickMap = useCallback((e:MapEventClick) => {
        const {
            tile
        } = e;

        const search = QueryString.stringify({
            quadKeys: [tile.getQuadKey()]
        });

        const suggest = store.orm.getters.filterOne(Suggest.modelName, {
            quadKey: tile.getQuadKey()
        });

        if(suggest) {
            const route = [
                ROUTES.suggestView,
                ROUTES.suggestViewReactions,
                ROUTES.suggestViewComments
            ].find((route:string) => {
                return matchPath(pathname, {
                    path: route,
                    exact: true
                });
            }) || ROUTES.suggestView;

            history.replace(
                generatePath(route, {
                    suggestId: suggest.id
                }) + (search ? `?${search}` : "")
            );
        }
    }, [pathname]);

    return (
        <Page className="page-suggest-view">
            <PageHeader deep={3}>
                <PageNavbar item spacing={3} justifyContent="space-between">
                    <Grid item>
                        <Grid container alignItems="flex-end">
                            <Typography
                              className="page-suggest-view__address"
                              variant="h3"
                              display="inline">
                                <PageBackButton
                                  onClick={handleClickBack} />

                                {address ? address : null}
                            </Typography>

                            <Typography
                              className="page-suggest-view__id"
                              variant="h4"
                              display="inline">
                                Suggestion #{slug}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Pagination
                          count={suggests.length}
                          page={page}
                          onChange={handleChangePage} />
                    </Grid>
                </PageNavbar>
            </PageHeader>

            <PageWrapper deep={3}>
                <PageContent item xs={12} md={5} lg={4}>
                    <PageColumn className="page-suggest-view__map">
                        <SuggestMap
                          suggestId={suggestId}
                          quadKeys={quadKeys}
                          onClick={handleClickMap} />
                    </PageColumn>

                    <PageColumn className="page-suggest-view__quality">
                        <PageSidebar>
                            <PageSidebarContent>
                                <SuggestDataQuality
                                  suggestId={suggestId} />
                            </PageSidebarContent>
                        </PageSidebar>
                    </PageColumn>
                </PageContent>

                <PageContent item xs={12} md={7} lg={5}>
                    <PageColumn className="page-suggest-view__content">
                        {suggest && (
                            <SuggestOverview
                              suggestId={suggestId}
                              quadKeys={quadKeys} />
                        )}
                    </PageColumn>
                </PageContent>

                <PageContent item xs={12} md={12} lg={3}>
                    <PageColumn className="page-suggest-view__rating">
                        <PageSidebar>
                            <PageSidebarContent>
                                <SuggestRating
                                  suggestId={suggestId}
                                  quadKeys={quadKeys} />
                            </PageSidebarContent>
                        </PageSidebar>
                    </PageColumn>

                    <PageColumn className="page-suggest-view__relative">
                        <PageSidebar>
                            <PageSidebarContent>
                                <SuggestTop5
                                  suggestId={suggestId} />
                            </PageSidebarContent>
                        </PageSidebar>
                    </PageColumn>
                </PageContent>
            </PageWrapper>
        </Page>
    );
};


export default SuggestViewPage;