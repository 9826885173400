const ROUTES = {
    home: "/",
    login: "/login",
    rating: "/rating",
    ratingParameter: "/rating/:parameterId",
    ratingView: "/rating-view",
    ratingViewParameters: "/rating-view/parameter",
    ratingViewParameter: "/rating-view/parameter/:parameterId",
    suggest: "/suggestion/map/:suggestId?",
    suggestView: "/suggestion/view/:suggestId",
    suggestViewReactions: "/suggestion/view/:suggestId/reactions",
    suggestViewComments: "/suggestion/view/:suggestId/comments"
};

const MENU = [
    {
        label: "Ratings",
        to: ROUTES.rating
    }
];


export {
    ROUTES,
    MENU
};