import React, {PropsWithChildren} from "react";
import {
    Grid,
    Typography
} from "@material-ui/core";

import {IBounds} from "src/types";

import {
    useOrmGetters,
    TileParameter
} from "src/store";

import "./index.scss";


interface Props {
    bounds?:IBounds;
    quadKeys?:string[];
}


interface TableProps {
    title:string;
}

const Table:React.FC<TableProps> = (props:PropsWithChildren<TableProps>) => {
    const {
        children,
        title
    } = props;

    return (
        <Grid container className="sidebar-parameters">
            {
                title && (
                    <Grid item xs={12} className="sidebar-parameters__title">
                        <Grid item>
                            <Typography variant="h6">
                                {title}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            }

            {children}
        </Grid>
    );
};


interface RowProps {
    title:any;
    value:any;
}

const Row:React.FC<RowProps> = (props:PropsWithChildren<RowProps>) => {
    const {
        title,
        value
    } = props;

    return (
        <Grid container
          className="parameter-totals__row"
          justifyContent="space-between">
            <Grid item>
                <Typography variant="body1">
                    {title}
                </Typography>
            </Grid>

            <Grid item>
                <Typography variant="body1">
                    {value}
                </Typography>
            </Grid>
        </Grid>
    );
};


const RatingParameterTotals:React.FC<Props> = (props:Props) => {
    const {
        bounds = null,
        quadKeys = []
    } = props;

    const orm = useOrmGetters();

    const {
        parameters: totals = []
    } = orm.getSummary("tile", {
        withSummary: true,
        ...(bounds ? {
            bounds
        } : {
            ...(quadKeys ? {
                quadKeys: quadKeys
            } : {})
        })
    });

    const topTotals = totals.sort((a:any, b:any) => a.rate > b.rate ? -1 : 1).slice(0, 3);
    const bottomTotals = totals.sort((a:any, b:any) => a.rate > b.rate ? 1 : -1).slice(0, 3);

    return (
        <Grid container className="parameter-totals">
            <Grid item
              className="parameter-totals__column"
              xs={6}>
                <Table title="Top">
                    {topTotals.map((parameterTotal:any) => {
                        const parameter = orm.byId(TileParameter.modelName, parameterTotal.parameterId);

                        return (
                            <Row
                              key={parameterTotal.parameterId}
                              title={parameter ? parameter.title : ""}
                              value={parameterTotal.rate + "%"} />
                        );
                    })}
                </Table>
            </Grid>

            <Grid item
              className="parameter-totals__column"
              xs={6}>
                <Table title="Bottom">
                    {bottomTotals.map((parameterTotal:any) => {
                        const parameter = orm.byId(TileParameter.modelName, parameterTotal.parameterId);

                        return (
                            <Row
                              key={parameterTotal.parameterId}
                              title={parameter ? parameter.title : ""}
                              value={parameterTotal.rate + "%"} />
                        );
                    })}
                </Table>
            </Grid>
        </Grid>
    );
};


export default RatingParameterTotals;