import React, {useCallback} from "react";
import {
    useLocation,
    useHistory,
    generatePath
} from "react-router";
import {
    makeStyles,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@material-ui/core";

import {ParameterIcon} from "src/views/blocks";

import {ROUTES} from "src/router";

import {
    useORM,
    ITileParameter,
    TileParameter
} from "src/store";


type Props = {
    id:any;
    active:boolean;
    onClick?:() => void;
};

const ParameterListItem:React.FC<Props> = (props:Props) => {
    const {
        id,
        active,
        onClick
    } = props;

    const classes = useStyles();
    const history = useHistory();
    const {search} = useLocation();

    const parameter:ITileParameter|null = useORM((orm) => {
        return orm.byId(TileParameter.modelName, id);
    });

    const {
        title,
        icon
    } = parameter || {};

    const handleClick = useCallback(() => {
        history.push(
            generatePath(ROUTES.ratingParameter, {
                parameterId: id
            }) + search
        );

        if(onClick) {
            onClick();
        }
    }, [id, search]);

    return (
        <ListItem
          classes={{
            root: classes.listItemRoot
          }}
          dense
          button
          selected={active}
          onClick={handleClick}>
            <ListItemIcon>
                <ParameterIcon
                  className={classes.avatarRoot}
                  color={"#41617F"}
                  src={icon} />
            </ListItemIcon>

            <ListItemText
              classes={{
                root: classes.listItemTextRoot,
                primary: classes.listItemTextPrimary
              }}
              primary={title} />
        </ListItem>
    );
};

ParameterListItem.defaultProps = {
    active: false
};


const useStyles = makeStyles(() => {
    return {
        avatarRoot: {
            width: 25,
            height: 25
        },
        listItemRoot: {
            padding: 0
        },
        listItemTextRoot: {
            marginTop: 6,
            marginBottom: 6
        },
        listItemTextPrimary: {
            fontSize: 15
        }
    };
});

export default ParameterListItem;