import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {Grid} from "@material-ui/core";

import {ROUTES} from "src/router";

import {useAuthGetters} from "src/store";


interface Props {}

const Home:React.FC<Props> = () => {
    const history = useHistory();
    const auth = useAuthGetters();

    useEffect(() => {
        if(auth.isLoggedIn) {
            history.push(ROUTES.rating);
        }
        else {
            history.push(ROUTES.login);
        }
    }, []);

    return (
        <Grid container>
            Home
        </Grid>
    );
};


export default Home;