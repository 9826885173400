import {useState, useEffect} from "react";
import {createStore} from "kpdux";
import {useSelector} from "react-redux";
import {reducer as formReducer} from "redux-form";
import * as lodash from "lodash";

import {apiFactory, ApiState} from "./modules/api.factory";
import {app, AppState} from "./modules/app.module";
import {auth, AuthState} from "./modules/auth.module";
import {ormFactory, OrmState} from "./modules/orm.factory";
import {storage} from "./modules/storage.module";
import {ui, UiState} from "./modules/ui.module";

import {Area} from "./models/Area";
import {ICityCenter, CityCenter} from "./models/CityCenter";
import {IDemandCategory, DemandCategory} from "./models/DemandCategory";
import {IDemandVote,DemandVote} from "./models/DemandVote";
import {ISuggest, Suggest} from "./models/Suggest";
import {SuggestCategory} from "./models/SuggestCategory";
import {SuggestAnalytic} from "./models/SuggestAnalytic";
import {SuggestComment} from "./models/SuggestComment";
import {ITile, Tile} from "./models/Tile";
import {TileAnalytic} from "./models/TileAnalytic";
import {ITileComment, TileComment} from "./models/TileComment";
import {ITileParameter, TileParameter} from "./models/TileParameter";

import {API_URL} from "src/env";


type State = {
    api:ApiState;
    app:AppState;
    auth:AuthState;
    orm:OrmState;
    ui:UiState;
};

const store = createStore({
    api: apiFactory(API_URL),
    app,
    auth,
    orm: ormFactory({
        models: [
            Area,
            CityCenter,
            DemandCategory,
            DemandVote,
            Suggest,
            SuggestAnalytic,
            SuggestCategory,
            SuggestComment,
            Tile,
            TileAnalytic,
            TileComment,
            TileParameter
        ]
    }),
    storage,
    ui
}, {
    form: formReducer
});

const useModuleGetters = (name:string):any => {
    return useSelector((state:any) => {
        return Object.keys(store[name]._data.getters).reduce((getters:any, key:string) => {
            Object.defineProperty(getters, key, {
                get: () => store[name][key](
                    state[name],
                    store[name].getters,
                    state,
                    store[name].rootGetters
                )
            });

            return getters;
        }, {});
    });
};

const useORM = (handle:(orm:any) => any) => {
    const orm = useOrmGetters();
    const newData = handle(orm);

    const [data, setData] = useState(newData);

    useEffect(() => {
        if(!lodash.isEqual(data, newData)) {
            setData(newData);
        }
    }, [newData]);

    return data;
};

const useAppGetters = () => {
    return useModuleGetters("app");
};

const useAuthGetters = () => {
    return useModuleGetters("auth");
};

const useOrmGetters = () => {
    return useModuleGetters("orm");
};

const useStorageGetters = () => {
    return useModuleGetters("storage");
};

const useUIGetters = () => {
    return useModuleGetters("ui");
};


export type {
    State,
    ICityCenter,
    IDemandCategory,
    IDemandVote,
    ISuggest,
    ITile,
    ITileComment,
    ITileParameter
};

export * from "./models/Area";

export {
    useModuleGetters,
    useAppGetters,
    useAuthGetters,
    useOrmGetters,
    useORM,
    useStorageGetters,
    useUIGetters,
    Area,
    CityCenter,
    DemandCategory,
    DemandVote,
    Suggest,
    SuggestCategory,
    SuggestAnalytic,
    SuggestComment,
    Tile,
    TileAnalytic,
    TileComment,
    TileParameter
};

export default store;