import React, {useState, useEffect, useCallback} from "react";
import {
    useHistory,
    useLocation,
    useParams,
    generatePath,
    Switch,
    Route
} from "react-router-dom";
import {
    Grid,
    Typography
} from "@material-ui/core";

import {
    parseQueryBounds,
    parseQuadKeys
} from "src/tools";

import {
    Page,
    PageHeader,
    PageNavbar,
    PageWrapper,
    PageContent,
    PageColumn,
    PageSidebar,
    PageSidebarContent,
    PageTabs,
    PageTab,
    PageBackButton
} from "src/views/blocks";

import {
    RatingViewTool,
    RatingViewMap,
    RatingViewDataQuality
} from "./blocks";

import {
    RatingGeneralRating,
    RatingParameterItem,
    RatingParameterList,
    RatingComments,
    RatingSuggestions
} from "./sidebars";

import {ROUTES} from "src/router";

import store, {
    useORM,
    CityCenter,
    Tile,
    TileAnalytic,
    TileParameter
} from "src/store";

import "./index.scss";


type Props = {
    //
};

type UrlParams = {
    parameterId?:string;
};


const RatingViewPage:React.FC<Props> = (props:Props) => {
    const history = useHistory();
    const {
        search
    } = useLocation();

    const {
        parameterId
    } = useParams<UrlParams>();

    const [quadKeys, setQuadKeys] = useState(parseQuadKeys(search));
    const [bounds, setBounds] = useState(parseQueryBounds(search));

    const {
        address
    } = useORM((orm) => {
        return orm.getSummary(Tile.modelName, {
            withSummary: true,
            parameterId,
            ...bounds ? {
                bounds
            } : {
                ...(quadKeys ? {
                    quadKeys
                } : {})
            }
        });
    });

    useEffect(() => {
        const loadData = async () => {
            await store.orm.search(CityCenter.modelName, {
                type: "overview"
            });

            await store.orm.search(TileParameter.modelName);
        };

        loadData();
    }, []);

    useEffect(() => {
        const quadKeys = parseQuadKeys(search);
        const bounds = parseQueryBounds(search);

        setQuadKeys(quadKeys);
        setBounds(bounds);
    }, [search]);

    useEffect(() => {
        const loadData = async () => {
            if(bounds || (quadKeys || []).length > 0) {
                await store.orm.search(Tile.modelName, {
                    withSummary: true,
                    parameterId: parameterId,
                    ...(bounds ? {
                        bounds: bounds
                    } : {
                        ...(quadKeys ? {
                            quadKeys: quadKeys
                        } : {})
                    })
                });

                await store.orm.search(TileAnalytic.modelName, {
                    withSummary: true,
                    parameterId: parameterId,
                    ...(bounds ? {
                        bounds: bounds
                    } : {
                        ...(quadKeys ? {
                            quadKeys: quadKeys
                        } : {})
                    })
                });
            }
        };

        loadData();
    }, [parameterId, quadKeys, bounds]);

    const handleClickBack = useCallback(() => {
        if(parameterId) {
            history.push(
                generatePath(ROUTES.ratingParameter, {
                    parameterId
                }) + search
            );
        }
        else {
            history.push(
                generatePath(ROUTES.rating) + search
            );
        }
    }, [parameterId, search]);

    return (
        <Page className="page-rating-view">
            <PageHeader deep={3}>
                <PageNavbar item spacing={3}>
                    <Grid item xs={12} md={6} lg={4}>
                        <Typography variant="h3">
                            <PageBackButton
                              onClick={handleClickBack} />

                            {address ? "Near " + address : ""}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <PageTabs>
                            <PageTab exact={false}
                              path="/rating-view"
                              to={"/rating-view" + search}
                              label="General" />

                            <PageTab exact={false}
                              path="/rating-view/parameter"
                              to={"/rating-view/parameter" + search}
                              label="Parameter" />
                        </PageTabs>
                    </Grid>
                </PageNavbar>
            </PageHeader>

            <PageWrapper deep={3}>
                <PageContent item xs={12} md={6} lg={4}
                  className="page-rating-view__content">
                    <PageColumn>
                        <RatingViewTool />
                    </PageColumn>

                    <PageColumn className="page-rating-view__map">
                        <RatingViewMap
                          parameterId={parameterId}
                          bounds={bounds}
                          quadKeys={quadKeys} />
                    </PageColumn>

                    <PageColumn className="page-rating-view__quality">
                        <PageSidebar>
                            <PageSidebarContent>
                                <RatingViewDataQuality
                                  parameterId={parameterId}
                                  bounds={bounds}
                                  quadKeys={quadKeys} />
                            </PageSidebarContent>
                        </PageSidebar>
                    </PageColumn>
                </PageContent>

                <PageContent item xs={12} md={6} lg={4}
                  className="page-rating-view__content">
                    <Switch>
                        <Route exact path={["/rating-view", "/rating-view/filtered"]}>
                            <RatingGeneralRating
                              parameterId={parameterId}
                              bounds={bounds}
                              quadKeys={quadKeys} />
                        </Route>

                        <Route exact path="/rating-view/parameter">
                            <RatingParameterList
                              parameterId={parameterId}
                              bounds={bounds}
                              quadKeys={quadKeys} />
                        </Route>

                        <Route path="/rating-view/parameter/:parameterId">
                            <RatingParameterItem
                              parameterId={parameterId}
                              bounds={bounds}
                              quadKeys={quadKeys} />
                        </Route>
                    </Switch>
                </PageContent>

                <PageContent item xs={12} md={12} lg={4}
                  className="page-rating-view__content">
                    <Switch>
                        <Route exact path={["/rating-view", "/rating-view/filtered"]}>
                            <RatingComments
                              hasParameter={false}
                              bounds={bounds}
                              quadKeys={quadKeys} />
                        </Route>

                        <Route exact path="/rating-view/parameter">
                            <RatingComments
                              hasParameter={true}
                              bounds={bounds}
                              quadKeys={quadKeys} />
                        </Route>

                        <Route path="/rating-view/parameter/:parameterId">
                            <RatingSuggestions />
                        </Route>
                    </Switch>
                </PageContent>
            </PageWrapper>
        </Page>
    );
};


export default RatingViewPage;