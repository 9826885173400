import React from "react";
import {compose} from "redux";
import {useLocation} from "react-router";
import {Link} from "react-router-dom";
import {
    Theme,
    createStyles,
    withStyles,
    WithStyles,
    StyledComponentProps,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@material-ui/core";
import {
    SentimentSatisfiedAlt as SentimentSatisfiedAltIcon,
    EmojiObjectsOutlined as EmojiObjectsOutlinedIcon,
    RoomOutlined as RoomOutlinedIcon
} from "@material-ui/icons";


interface Props extends WithStyles {}

interface DashboardSidebarProps extends StyledComponentProps {}


const DashboardSidebar:React.FC<Props> = (props:Props) => {
    const {
        classes
    } = props;

    const items = [
        {
            title: "Ratings",
            path: "/rating",
            to: "/rating",
            icon: SentimentSatisfiedAltIcon
        },
        {
            title: "Suggestions",
            to: "/suggestion",
            icon: EmojiObjectsOutlinedIcon
        },
        {
            title: "Demand",
            to: "/demand",
            icon: RoomOutlinedIcon
        }
    ];

    const location = useLocation();

    return (
        <div className="dashboard-sidebar">
            <List
              className="dashboard-sidebar__list"
              classes={{
                root: classes.listRoot
              }}>
                {
                    items.map((item, index) => {
                        return (
                            <ListItem
                              key={index}
                              button
                              classes={{
                                root: classes.listItemRoot,
                                selected: classes.listItemSelected
                              }}
                              component={Link}
                              to={item.to}
                              selected={location.pathname.indexOf(item.path || item.to) === 0}>
                                {
                                    item.icon && (
                                        <ListItemIcon>
                                            <item.icon />
                                        </ListItemIcon>
                                    )
                                }

                                <ListItemText
                                  className="dashboard-sidebar__title"
                                  primary={item.title} />
                            </ListItem>
                        );
                    })
                }
            </List>
        </div>
    );
};


const styles = (theme:Theme) => createStyles({
    listRoot: {
        padding: 0
    },
    listItemRoot: {},
    listItemSelected: {}
});

export default compose<React.ComponentType<DashboardSidebarProps>>(
    withStyles(styles)
)(DashboardSidebar);