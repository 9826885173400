import React, {useCallback} from "react";
import {
    useHistory,
    useLocation,
    generatePath
} from "react-router";
import {
    Grid,
    Typography,
    Button,
    Divider,
    CircularProgress
} from "@material-ui/core";
import {
    Help as HelpIcon
} from "@material-ui/icons";

import {
    IBounds,
    ITileParameter
} from "src/types";

import {
    PageSidebar,
    PageSidebarContent,
    PageScroll,
    Emotion,
    RatingTrend
} from "src/views/blocks";

import RatingParameterTotals from "../RatingParameterTotals";
import SidebarParameters from "../SidebarParameters";

import {ROUTES} from "src/router";

import {
    useORM,
    Tile,
    TileAnalytic,
    TileParameter
} from "src/store";


interface Props {
    parameterId?:string;
    quadKeys?:string[];
    bounds?:IBounds;
}


const RatingSidebar:React.FC<Props> = (props:Props) => {
    const {
        parameterId,
        bounds,
        quadKeys = []
    } = props;

    const history = useHistory();
    const {search} = useLocation();

    const query = {
        withSummary: true,
        parameterId: parameterId,
        ...(bounds ? {
            bounds: bounds
        } : {
            ...(quadKeys ? {
                quadKeys : quadKeys
            } : {})
        })
    };

    const parameter:ITileParameter|null = useORM((orm) => {
        return orm.byId(TileParameter.modelName, parameterId);
    });

    const {
        address = "",
        votes = 0,
        rate = 0,
        commentsGeneral = 0,
        // commentsPositive = 0,
        // commentsNegative = 0,
        commentsNeutral = 0,
        commentsParameterPositive = 0,
        commentsParameterNegative = 0,
        relative = []
    } = useORM((orm) => {
        return orm.getSummary(Tile.modelName, query);
    });

    const {
        loaded: tileAnalyticLoaded = true,
        loading: tileAnalyticLoading = false
    } = useORM((orm) => {
        return orm.getMeta(TileAnalytic.modelName, query);
    });

    const {
        totals = []
    } = useORM((orm) => {
        return orm.getSummary(TileAnalytic.modelName, query);
    });

    const commentTotals:any[] = [
        {
            title: "General",
            value: commentsGeneral,
            color: "#41617F"
        },
        {
            title: "Positive",
            value: commentsParameterPositive,
            color: "#569E32"
        },
        {
            title: "Neutral",
            value: commentsNeutral,
            color: "#d4d4d4"
        },
        {
            title: "Negative",
            value: commentsParameterNegative,
            color: "#E51A24"
        }
    ].filter((total) => total.value > 0);

    const handleClickView = useCallback(() => {
        if(!parameterId) {
            history.push(`${ROUTES.ratingView}${search}`);
        }
        else {
            history.push(
                generatePath(ROUTES.ratingViewParameter, {
                    parameterId
                }) + search
            );
        }
    }, [parameterId, search]);

    // if(loading) {
    //     return (
    //         <PageSidebar className="sidebar">
    //             <PageSidebarContent>
    //                 <Grid
    //                   container
    //                   style={{height: "100%"}}
    //                   justifyContent="center"
    //                   alignItems="center"
    //                   direction="column">
    //                     <CircularProgress />
    //                 </Grid>
    //             </PageSidebarContent>
    //         </PageSidebar>
    //     );
    // }

    return (
        <PageSidebar className="sidebar">
            <PageScroll>
                <PageSidebarContent>
                    <Grid container style={{padding: "0 0 5px 0"}}>
                        <Grid item
                          style={{padding: "0 6px"}}>
                            <Typography
                              variant="h4"
                              color={parameter ? "textSecondary" : "textPrimary"}>
                                Snapshot
                            </Typography>
                        </Grid>

                        {
                            parameter ? (
                                <Grid item
                                  style={{padding: "0 6px"}}>
                                    <Typography
                                      variant="h4"
                                      color="textPrimary">
                                        {parameter ? parameter.title : ""}
                                    </Typography>
                                </Grid>
                            ) : null
                        }
                    </Grid>

                    <Grid container
                      style={{
                        padding: "10px 0px 10px 6px"
                      }}
                      justifyContent="space-between"
                      alignContent="center"
                      alignItems="center"
                      wrap="nowrap">
                        <Grid item>
                            <Grid container>
                                <Typography variant="h5">
                                    {address ? `Near ${address}` : ""}
                                </Typography>
                            </Grid>

                            <Grid container style={{marginBottom: 8}}>
                                <Typography
                                  style={{
                                    fontSize: "17px",
                                    color: "#707070",
                                    lineHeight: 1
                                  }}>
                                    {quadKeys.length} tile{quadKeys.length > 1 ? "s" : ""} selected
                                </Typography>
                            </Grid>
                        </Grid>

                        {quadKeys.length > 0 && (
                            <Grid item style={{flexBasis: "118px"}}>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  size="medium"
                                  fullWidth
                                  onClick={handleClickView}>
                                    View
                                </Button>
                            </Grid>
                        )}
                    </Grid>

                    <Divider />

                    <Grid container
                      style={{
                        paddingTop: "20px",
                        paddingBottom: "20px"
                      }}
                      justifyContent="space-between"
                      alignContent="center"
                      alignItems="center">
                        <Grid item>
                            <Typography
                              style={{
                                display: "flex",
                                alignItems: "center"
                              }}
                              variant="h2">
                                <Emotion
                                  style={{marginRight: "15px"}}
                                  size="medium"
                                  {...votes > 0 ? {
                                    rate: rate
                                  } : {
                                    type: "neutral",
                                    color: "light"
                                  }} />

                                {votes > 0 ? rate + "%" : ""}
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Typography
                              style={{fontSize: "20px"}}
                              variant="body1">
                                {votes > 0 ? votes + " Votes" : ""}
                            </Typography>
                        </Grid>
                    </Grid>

                    {tileAnalyticLoading && (
                        <Grid container
                          justifyContent="center"
                          alignItems="center">
                            <Grid item
                              style={{
                                marginTop: 10,
                                marginBottom: 10
                              }}>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    )}

                    {tileAnalyticLoaded && (
                        <Grid item style={{paddingLeft: "6px"}}>
                            <RatingTrend
                              data={totals.map((total:any) => {
                                return {
                                    title: total.date,
                                    value: total.rate || 0
                                };
                              })} />
                        </Grid>
                    )}

                    {!parameterId && votes > 0 && (
                        <Grid container>
                            <Grid item xs={12}>
                                <Divider color={"#B9B9B9"} />
                            </Grid>

                            <Grid item xs={12}
                              style={{padding: "10px 10px 10px 6px"}}>
                                <RatingParameterTotals
                                  bounds={bounds}
                                  quadKeys={quadKeys} />
                            </Grid>
                        </Grid>
                    )}

                    {relative.length > 0 || commentTotals.length > 0 && (
                        <Divider color={"#B9B9B9"} />
                    )}

                    <Grid container>
                        <Grid item xs={6}
                          style={{padding: "10px 10px 10px 6px"}}>
                            {relative.length > 0 && (
                                <SidebarParameters
                                  title="Relative popularity"
                                  icon={<HelpIcon style={{color: "#D8D8D8"}} />}
                                  parameters={relative.map((total:any) => {
                                    return {
                                        title: total.key,
                                        rate: (total.rate > 0 ? "+" : "") + total.rate
                                    };
                                  })} />
                            )}
                        </Grid>

                        <Grid item xs={6}
                          style={{padding: "10px 6px 10px 10px"}}>
                            {commentTotals.length > 0 && (
                                <SidebarParameters
                                  title="Comments"
                                  variant="circular"
                                  parameters={commentTotals} />
                            )}
                        </Grid>
                    </Grid>
                </PageSidebarContent>
            </PageScroll>
        </PageSidebar>
    );
};


export default RatingSidebar;